<template>
	<div v-if="canRotate">
		<v-layout row wrap>
			<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
				<v-layout row align-center justify-center>
					<RotateDocumentButton
					    v-model="model"
							direction="left"
							:flat="flat"
					/>
					<RotateDocumentButton
					    v-model="model"
							direction="right"
							:flat="flat"
					/>
				</v-layout>
			</v-flex>
			<v-flex v-else-if="listTileDisplay" shrink>
				<v-list-tile avatar :disabled="rotateDocumentRightLoading" @click="rotateDocument('left')">
					<v-list-tile-avatar>
						<v-progress-circular v-if="rotateDocumentLeftLoading" color="primary" indeterminate />
						<v-icon v-else color="primary">rotate_left</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title>{{ $tc('actions.rotate_document_left', nodes.length) }}</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
				<v-list-tile avatar :disabled="rotateDocumentLeftLoading" @click="rotateDocument('right')">
					<v-list-tile-avatar>
						<v-progress-circular v-if="rotateDocumentRightLoading" color="primary" indeterminate />
						<v-icon v-else color="primary">rotate_right</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title>{{ $tc('actions.rotate_document_right', nodes.length) }}</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import RotateDocumentMixin from "@/components/Documents/Actions/Rotate/RotateDocumentMixin";

export default {
	name: 'RotateDocumentButtons',
	components: {
		RotateDocumentButton: () => import('@/components/Documents/Actions/Rotate/RotateDocumentButton.vue')
	},
	mixins: [DocumentVersionsModuleGuard, RotateDocumentMixin],
	props: {
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		},
		flat: {
			type: Boolean,
			required: false,
			default: true
		},
		listTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		simpleButtonDisplay: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: function () {
		return {
			rotateDocumentLeftLoading: false,
			rotateDocumentRightLoading: false
		}
	},
	computed: {
		model: {
			get () {
				return Array.isArray(this.value) ? this.value : [this.value] || []
			},
			set (value) {
				this.$emit('input', value)
			}
		},
		nodes () {
			let result = this.value
			if (!Array.isArray(this.value)) {
				result = [this.value]
			}
			return result
		},
	}
}
</script>
